import { DOCUMENT, DatePipe, DecimalPipe } from '@angular/common';
import {
  Component,
  OnInit,
  Signal,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CtaComponent } from '@components/cta/cta.component';
import { DialogComponent } from '@components/dialog/dialog.component';
import { EnvironmentIndicatorComponent } from '@components/environment-indicator/environment-indicator.component';
import { FeatureFlagDirective } from '@directives/flag-directives/feature-flag.directive';
import { ColorSchemeEnum } from '@enums/color-scheme.enum';
import { AvailableLanguages } from '@models/languages.model';
import { Nullable } from '@models/nullable.model';
import { TranslateService } from '@ngx-translate/core';
import { TranslatePipe } from '@pipes/translate.pipe';
import { CheckForUpdateService } from '@services/check-for-update.service';
import { DialogService, DialogViewInfo } from '@services/dialog.service';
import { MeService } from '@services/me.service';
import { PrimeTranslateService } from '@services/prime-translate.service';
import { MeConfigStateService } from '@state-management/me-config-state';
import { SystemStateService } from '@state-management/system-state';
import { take, tap } from 'rxjs';
import { IconComponent } from './components/icon/icon.component';
import { SnackbarContainerComponent } from './components/toast/snackbar-container/snackbar-container.component';

@Component({
  selector: 'nax-root',
  standalone: true,
  templateUrl: './app.component.html',
  imports: [
    RouterOutlet,
    DialogComponent,
    CtaComponent,
    TranslatePipe,
    SnackbarContainerComponent,
    IconComponent,
    EnvironmentIndicatorComponent,
    FeatureFlagDirective,
  ],
  providers: [DecimalPipe, DatePipe, TranslatePipe],
})
export class AppComponent implements OnInit {
  private _document = inject(DOCUMENT);
  private readonly translateService = inject(TranslateService);
  private readonly checkForUpdateService = inject(CheckForUpdateService);
  private readonly dialogService = inject(DialogService);
  private readonly meConfigStateService = inject(MeConfigStateService);
  private readonly meService = inject(MeService);
  private readonly primeTranslateService = inject(PrimeTranslateService);
  private readonly systemStateService = inject(SystemStateService);

  protected isOffline = computed(() => this.systemStateService.isOffline());
  protected dialogInfo: Signal<DialogViewInfo> = computed(() => {
    const dialogInfo = this.dialogService.dialogInfo();
    if (!dialogInfo || dialogInfo.type === 'snackbar') {
      untracked(() => this.openDialog.set(false));
      return { message: null, open: false };
    }
    untracked(() => this.openDialog.set(true));
    const message =
      typeof dialogInfo.message === 'string'
        ? [dialogInfo.message]
        : dialogInfo.message;
    return { type: 'dialog', ...(dialogInfo ?? {}), message, open: true };
  });
  protected openDialog = signal(false);

  constructor() {
    this.setThemePreferencesEffect();
    this.setLanguagePreferencesEffect();
    this.meService.initMeStates();
  }

  ngOnInit(): void {
    this.checkForUpdateService.start();
  }

  private setThemePreferencesEffect(): void {
    effect(() => {
      if (
        this.meConfigStateService.getValue('theme')() === ColorSchemeEnum.Dark
      ) {
        this._document.documentElement.setAttribute('data-theme', 'dark');
      } else {
        this._document.documentElement.removeAttribute('data-theme');
      }
    });
  }

  private setLanguagePreferencesEffect(): void {
    effect(() => {
      const language = this.meConfigStateService.getValue('language')();
      this.setLanguageTranslations(language);
    });
  }

  private setLanguageTranslations(
    language: Nullable<AvailableLanguages>,
  ): void {
    if (!language) return;
    if (language !== this.translateService.currentLang) {
      this._document.documentElement.lang = language;
      this.translateService
        .use(language)
        .pipe(
          take(1),
          tap(() => this.primeTranslateService.setTranslation()),
        )
        .subscribe();
    }
  }
}
